<template>
    <div>
        <h1 class="font-weight-medium">{{this.$store.state.organization.name}}</h1>
        <p class="text-h6">{{ $t('header.title')}}</p>
        <div class="steps">
        <div class="circle-container">
        <div class='circle' v-bind:class="{ active: this.$route.fullPath.includes('lomake')}">1</div>
        </div>
        <div class="circle-container">
            <div class='circle' v-bind:class="{ active: this.$route.fullPath.includes('yhteystiedot')}">2</div>
        </div>

        <div class="circle-container">
            <div class='circle' v-bind:class="{ active: this.$route.fullPath.includes('yhteenveto')}">3</div>
        </div>
        </div>
    </div>
</template>

<style scoped>

.steps {
    display: flex;
    margin-bottom: 25px;
}

.circle-container {
    position: relative;
    padding-right: 40px
}

.circle {
  height: 45px;
  line-height: 42px;
  width: 45px;
  border-radius: 50%;
  border: 2px solid;
  position: relative;
  color: #D2D3D5;
  font-weight: 700;
  font-size: 16px;
  border-color: #D2D3D5;
  text-align: center;
  flex-shrink: 0;
  z-index: 2;
  background: #fff;
}

.circle.active {
    border-color: #000;
    color: #000;
}

.circle-container:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: -3px;
  border: 1px solid #D2D3D5;
  height: 1px;
  width: 45px;
}

.circle-container:nth-child(3)::before {
    display: none;
    }
</style>

<script>
export default {
    data() {
        return {
            organisation: {
                name: this.$route.params.report
            }
        }
    }
}
</script>
